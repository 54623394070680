<template>
    <div class="complete-profilepage">
        <v-row class="mt-2">
            <v-col cols="12" md="3">
                <div class="complete-profilepage__steps " :class="isMd ?  '': 'pt-14 mt-6'">
                    <div
                            class="complete-profilepage__step d-flex align-center justify-space-between mb-2 cursor-pointer pa-2 radius-5"
                            :class="step.pageActive ? 'complete-profilepage__step--active' : ''"
                            v-for="(step, i) in steps"
                            :key="i"
                            @click="$router.push(step.route)"
                    >
                        <span class="font-20">{{ step.title }}</span>
                        <div
                                class="complete-profilepage__step__count d-flex align-center justify-center"
                                :class="
                step.pageCompleted
                  ? 'complete-profilepage__step__count--completed'
                  : ''
              "
                        >
              <span class="font-16" v-if="!step.pageCompleted">{{
                  i + 1
                  }}</span>
                            <v-icon color="white" size="16" v-if="step.pageCompleted"
                            >mdi-check
                            </v-icon
                            >
                        </div>
                    </div>
                    <div
                            class="complete-profilepage__step d-flex align-center justify-space-between mb-2 cursor-pointer pa-2 radius-5"
                            @click="$router.push('/dashboard/interviews')"
                    >
                        <v-icon color="darkprimary" size="24"
                        >mdi-arrow-left-thin-circle-outline
                        </v-icon
                        >
                        <span class="font-18 mt-1">{{
                            $t("dashboard.goToDashboard")
                            }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="9">
                <div class="text-center mb-4">
                    <router-link to="/">
                        <img
                                width="200px"
                                :src="require('@/assets/images/logo.png')"
                                alt="logo"
                        />
                    </router-link>
                </div>

                <div class="complete-profilepage__content">
                    <transition name="fade" mode="out-in">
                        <div :class="isMd ? '': 'pa-4'">

                            <router-view @nextHandler="nextHandler"/>
                        </div>
                    </transition>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import i18n from "@/i18n";

export default {
    data: () => ({
        steps: [
            {
                title: i18n.t('completeProfile.getStarted'),
                route: "/complete-profile/get-started",
                pageActive: false,
                pageCompleted: true,
                name: "get-started",
            },
            {
                title: i18n.t('completeProfile.personalInfo') ,
                route: "/complete-profile/personal-info",
                pageActive: false,
                pageCompleted: false,
                name: "personal-info",
            },
            {
                title: i18n.t('completeProfile.jobExperience') ,
                route: "/complete-profile/job-experience",
                pageActive: false,
                pageCompleted: false,
                name: "job-experience",
            },
            {
                title: i18n.t('completeProfile.degrees'),
                route: "/complete-profile/degrees",
                pageActive: false,
                pageCompleted: false,
                name: "degrees",
            },
            {
                title: i18n.t('completeProfile.socialMedia'),
                route: "/complete-profile/social-media",
                pageActive: false,
                pageCompleted: false,
                name: "social-media",
            },
            {
                title: i18n.t('completeProfile.futureJob'),
                route: "/complete-profile/future-job",
                pageActive: false,
                pageCompleted: false,
                name: "future-job",
            },
            {
                title: i18n.t('completeProfile.contactInfo'),
                route: "/complete-profile/contact-info",
                pageActive: false,
                pageCompleted: false,
                name: "contact info",
            },
        ],
    }),
    watch: {
        "$route.params": {
            handler() {
                this.checkRouteActive();
            },
        },
    },
    computed: {
        ...mapGetters([
            "profileNew",
            "profileNewMedia",
            "profileNewJob",
            "profileDegrees",
        ]),
    },
    methods: {
        checkRouteActive() {
            this.steps.forEach((step) => {
                if (step.name == this.$route.name) {
                    step.pageActive = true;
                } else {
                    step.pageActive = false;
                }
            });
        },
        checkCompletedPage() {

            if (
                this.profileNew.first_name &&
                this.profileNew.last_name &&
                this.profileNew.email &&
                this.profileNew.country_code &&
                this.profileNew.phone &&
                this.profileNew.nationality &&
                this.profileNew.specialization &&
                this.profileNew.experience_id &&
                this.profileNew.healthcare_provider_type_id
            ) {
                this.steps[1].pageCompleted = true;
            } else {
                this.steps[1].pageCompleted = false;
            }
            if (this.profileNewJob > 0) {
                this.steps[2].pageCompleted = true;
            } else {
                this.steps[2].pageCompleted = false;
            }
            if (this.profileDegrees > 0) {
                this.steps[3].pageCompleted = true;
                this.steps[4].pageCompleted = true;
            } else {
                this.steps[3].pageCompleted = false;
            }
            // this.profileNewMedia.forEach((media) => {
            //     if (media.doctor_social && media.doctor_social.value && media.doctor_social.value != "null") {
            //         isSocialMedia = true;
            //     }
            // });

            if (this.profileNew.job_type && this.profileNew.future_job_location) {
                this.steps[5].pageCompleted = true;
            } else {
                this.steps[5].pageCompleted = false;
            }

            if (
                this.profileNew.cv &&
                this.profileNew.degree_file
            ) {
                this.steps[6].pageCompleted = true;
            } else {
                this.steps[6].pageCompleted = false;
            }
        },
        async nextHandler(step) {
            await this.$store.dispatch("setProfileNew");
            await this.checkCompletedPage();
            if (step <= 6) {
                this.$router.push(this.steps[step].route);
            } else {
                let notCompletedSteps = this.steps.filter(
                    (step) => !step.pageCompleted
                );

                if (notCompletedSteps.length > 0) {
                    this.$router.push(notCompletedSteps[0].route);
                    this.$store.dispatch("showSnack", {
                        text: "Your Profile Is Still Not Completed",
                        color: "error",
                    });
                } else {
                    this.$router.push("/complete-profile/completed");
                }
            }
        },
    },
    async created() {
        this.checkRouteActive();
        await this.$store.dispatch("setProfileNew");
        await this.checkCompletedPage();
    },
};
</script>

<style lang="scss">
.complete-profilepage {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding: 20px 20px;

  &__content {
    padding: 0px 40px;
  }

  &__steps {
  }

  &__step {
    transition: 0.3s;

    &__count {
      background: white;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      line-height: 27px;
      transition: 0.3s;

      span {
        transition: 0.3s;
        color: $darktext;
      }

      &--completed {
        background: $register-primary;

        span {
          color: white;
        }
      }
    }

    &--active {
      box-shadow: 0px 12px 20px 0px #38a8932d;

      span {
        color: $register-primary;
      }
    }
  }

  @media (max-width: 600px) {
    &__content {
      padding: 0px !important
    }
  }
}
</style>
